.bm-burger-button {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.bm-burger-bars {
  background: #373a47;
}

.bm-burger-bars-hover {
  background: rgb(156, 156, 156);
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-menu-wrap {
  position: fixed;
  height: 290px !important;
  top: 70px !important;
}
.bm-menu {
    background: #303159;
    padding: 0px;
  font-size: 1.15em;
  height: 290px !important;
}
.bm-morph-shape {
  fill: #303159;
}
.bm-item-list {
  color: #b8b7ad;
  padding: 10px;
}
.bm-item {
  display: inline-block;
  color: #fff !important;
}
.bm-overlay {
  background: #303159 !important;
  top: 100px !important;
  width: 100% !important;
  height: 260px !important;
}
@media only screen and (min-width: 600px){
    .bm-burger-button {
        display: none;
      }
      
}