.BigContainer{
    padding: 15px 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1100px;
}
.firstSide{
    width: 55%;
    display: flex;
    flex-wrap: wrap;
    padding: 100px 100px;
    justify-content: center;
    align-self: flex-start;
}
.firstSideH2{
    max-width: 500px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: 0.0016em;
    color: #3C3B6E;
    align-self: flex-start;
}
.firstSideInt{
    max-width: 500px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0016em;
    color: #313059;
}
.secondSide{
    width: 45%;
    padding: 30px 45px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}
.secondSideH4{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #1E193D;
    padding: 10px 8px;
    border-bottom: 1px solid #CACDD2;
    margin-bottom: 10px;
}
.UpperInstructionP{
    padding: 5px 8px;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
}
.form{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.formDiv{
    border: 1px solid #3C3B6E;
    padding: 30px 22px;
    width: 100%;
    margin-bottom: 15px;
}
.formH5{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #3C3B6E;
    padding: 10px 22px;
    width: 100%;
}
.formListContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.formList{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
}
.formList2{
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
}
.formListStyle{
    width: 6px;
    height: 6px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 50%;
    background: #313059;
}
.formP{
    width: 100%;
}
.fileBox{
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    min-height: 37px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
}
.fileBox2{
    width: 50%;
}
.files::placeholder{
    color: #313059;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
}
.fileLabel{
    border-left: 1px solid #C4C4C4;
    height: inherit;
    padding: 5px 15px;
    cursor: pointer;
}
.formTextStyle{
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #313059;
}
.list{
    width: calc(100% - 16px);
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
}
.listmargin{
    margin-top: 10px;
}
.flexBtn{
    justify-content: space-between;
}
.radBtn{
    margin-right: 10px;
    /* padding: 2px; */
    border: 0px;
    /* width: 50%; */
    height: 0.6em;
}
.radBtn3{
    margin-right: 5px;
}
.radBtn2{
    /* margin-right: 2px; */
    /* padding: 2px; */
    border: 0px;
    /* width: 50%; */
    height: 0.6em;
}
.labelBtn2{
    margin-right: 7px;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
}
.labelBtn{
    width: 95%;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
}
.spanBtn{
    width: 5%;
    margin-right: 5px;
    text-align: left;
}
.divBtn{
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
    color: #313059;
    padding-top: 10px;
    padding-bottom: 20px;
}
/*  */
.formCom{
    display: flex;
    flex-wrap: wrap;
    gap: 13px 0px;
    margin-bottom: 15px;
}
.inputDiv{
    width: 100%;
}
.input{
    width: 100%;
    /* height: 25px; */
    padding: 10px 5px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
}
.input::placeholder{
    color: #313059;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
}
.btn{
    margin-top: 25px;
    padding: 10px 25px;
    align-self: center;
    background: #3C3B6E;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #3C3B6E;
    margin-right: 0;
    margin-left: 0;
}
.btnFirstChild{
    border-right: none;
    border-collapse: collapse;
    border-bottom-right-radius: 0%;
    border-top-right-radius: 0%;
}
.btnLastChild{
    border-left: none;
    border-collapse: collapse;
    border-bottom-left-radius: 0%;
    border-top-left-radius: 0%;
}
.UlListStyle{
    list-style: none;
}
.UlLiStyle{
    color: #313059;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
}
@media only screen and (max-width:320px){
    .labelBtn2{
        margin-right: 7px;
        font-size: 10px;
        text-align: justify;
        letter-spacing: 0.0016em;
        color: #313059;
    }    
}
@media only screen and (max-width:600px){
    .BigContainer{
        padding: 0px 0px;
    }
    .Container{
        width: 100%;
        padding: 15px 25px;
    }
    .firstSide{
        display: none;
    }
    .secondSide{
        width: 100%;
        max-width: 100%;
        padding: 30px 15px;
    } 
    .UpperInstructionP{
        padding: 5px 8px;
        font-style: normal;
        font-size: 16px;
        line-height: 25px;
        text-align: justify;
        letter-spacing: 0.0016em;
        color: #313059;
    } 
    .largeH5{
        font-size: 16px;
        line-height: 20px;
    }      
    .input{
        padding: 5px 5px;
    }  
    .formList{
        font-size: 14px;
    }          
}
@media only screen and (min-width:601px) and (max-width:992px){
    .BigContainer{
        padding: 0px 0px;
    }
    .Container{
        width: 100%;
        padding: 15px 70px;
    }    
    .firstSide{
        padding: 100px 0px;
        padding-right: 20px;
    }  
    .secondSide{
        width: 45%;
        padding: 30px 20px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
    }  
}