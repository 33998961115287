.becomeatutor-wrapper {
  padding: 4rem 0;
}
.becomeatutor-wrapper p{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0016em;
  color: #313059;
}

@media screen and (max-width: 768px) {
  .offers {
    text-align: center;
  }
  .offers p {
    padding-left: 40px;
    padding-right: 40px;
  }
}