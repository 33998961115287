.mainExamContainer{
    padding: 30px 70px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.examWidth{
    width: 1100px;
}
.firstSection{
    background: #fff;
    border-bottom: 1px solid #C4C4C4;
}
.examImage{
    background-image: url('./images/exam.svg');
    background-repeat: no-repeat;
    background-position: right;
    height: 200px;
}

.firstSectionDivH4{
    width: 100%;
    font-size: 45px;
    line-height: 55px;
    letter-spacing: -0.02em;
    color: #313059;;
}
.firstSectionDivP{
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #313059;;
}
.firstSectionDivButton{
    background-color: #3C3B6E;
    color: #fff;
}
.mainP{
    padding: 30px 250px;
    font-size: 24px;
    line-height: 29px;
    color: #3C3B6E;
    text-align: justify;
}
.mainFSection{
    padding: 30px 250px;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.mainListsContainer{
    padding: 0px 10px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 45%;
    height: 525px;
}
.width{
    width: 100%;
    padding: 10px;
}
.mainListLiH5{
    width: 100%;
    padding: 8px 1px;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.0016em;
    color: #000000;
    border-bottom: 1px solid #3C3B6E;
}
.mainSmallP{
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #313059;
    padding: 30px 1px;
}
.satP{
    background: #F4F4F4;
}
.btnDiv{
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}
.theBtns{
    padding: 10px 20px;
    background: #3C3B6E;
    border-radius: 4px;
    color: #CDCDCD;
    font-weight: 600;
}
.secBtn{
    background: #CDCDCD;
    color: #3C3B6E;
    border: 1px solid #3C3B6E;
}
.mainP2{
    padding: 0 0;
} 
/* PRICE */
/* .priceContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
} */
.priceContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100px;
}
.mainPrice{
    /* top: 100px; */
    width: 1100px;
    background-color: #fff;
    /* position: absolute; */
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.0016em;
    color: #1E193D;
    padding: 0px 20px;
}
.divPrice{
    width: 50%;
    padding: 25px 140px 100px 70px;
}
.divPriceBorder{
    border-right: 2px solid #3C3B6E;
}
.priceH5{
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
.examTypeContainer{
    display: flex;
    gap: 20px 10px;
    flex-wrap: wrap;
    list-style: none;
}
.examType{
    width: calc((100% / 3) - 10px);
    border: 1px solid #C4C4C4;
    padding: 10px 5px;
}
.classType{
    width: calc((100% / 2) - 10px);
    border: 1px solid #C4C4C4;
    padding: 10px 5px;
}
.classTrainingType{
    width: calc(100% / 2);
}
.table{
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
}
.th{
    border-bottom: 1px solid black;
    text-align: left;
}
.td{
    padding: 12px 15px;
    text-align: left;
}
.marginTop{
    margin-top: 10px;
}
.marginDown{
    margin-bottom: 20px;
}
.quesDiv{
    display: flex;
}
.quesP{
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
}
.form{
    width: 100%;
    padding: 10px 20px 10px 0px;
    display: flex;
    flex-wrap: wrap;
}
.labelPadding{
    padding-top: 2px;
    padding-bottom: 5px;
}
.secondSec{
    border: 1px solid #313059;
    margin-top: 100px;
    padding: 25px 30px;
}
.avatarContainer{
    display: flex;
    width: 90%;
    /* justify-content: space-between; */
    gap: 10px 0px;
    align-items: center;
}
.avatar{
    margin-top: 20px;
    width: 101px;
    height: 101px;
    border-radius: 50%;
    border: 1px solid #3C3B6E;
    background-color: #C4C4C4;
}
.avatarFile{
    width: 40%;
    height: 50px;
}
.td2{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0016em;
    color: #313059;
    padding: 20px 0;
}
.td3{
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #313059;
}
.tdBorder{
    border-top: 2px solid #3C3B6E;
}
@media only screen and (max-width:600px){
    .examImage{
        background-position: top;
        background-size: 70%;
        height: 250px;
        justify-content: center;
    }  

    .mainExamContainer{
        padding: 30px 25px;
    }
    .examWidth{
        width: 100%;
    }
    .firstSectionDivH4{
        font-size: 24px;
        line-height: 32px;
    }
    .firstSectionDivP{
        font-size: 16px;
        line-height: 26px;
    }    
    .mainP{
        padding: 30px 0px;
        font-size: 18px;
        line-height: 22px;
    } 
    .mainUl{
        padding: 30px 0px;  
    }   
    .mainFSection{
        padding: 30px 0px;
    }  
    .mainListsContainer{
        width: 100%;
    }
    .mainListLiH5{
        font-size: 14px;
        line-height: 22px;
    }
    .mainSmallP{
        font-size: 16px;
        line-height: 20px;
    }
    .btnDiv{
        width: 100%;
        flex-wrap: wrap;
    }   
    .theBtns{
        width: 100%;
        font-size: 14px;
    }
    .secBtn{
        margin-top: 10px;
        background: #CDCDCD;
        color: #3C3B6E;
        border: 1px solid #3C3B6E;
    } 
    /*  */
    .mainPrice{
        width: 90%;
        font-size: 17px;
        line-height: 21px;
        padding: 0px 0px;
    }    
    .divPrice{
        width: 100%;
        padding: 25px 25px 25px 25px;
    }   
    .divPriceBorder{
        border-right: none;
    }
    .examType{
        width: calc((100% / 2) - 10px);
    }   
    .classType{
        width: 90%;
        border: 1px solid #C4C4C4;
        padding: 10px 5px;
    }     
    .form{
        padding: 10px 0px 10px 0px;
    }   
    .td2{
        font-size: 14px;
        line-height: 22px;
        padding: 10px 0;
    }
    .td3{
        font-size: 14px;
        line-height: 24px;
    }   
    .secondSec{
        margin-bottom: 50px;
    }
}
@media only screen and (min-width:601px) and (max-width:992px){
    .examImage{
        background-position: right;
        background-size: 58%;
    }  

    .examWidth{
        width: 100%;
    }
    .firstSectionDivH4{
        font-size: 24px;
        line-height: 32px;
    }
    .firstSectionDivP{
        font-size: 16px;
        line-height: 26px;
    }    
    .mainP{
        padding: 30px 0px;
        font-size: 18px;
        line-height: 22px;
    }    
    .mainFSection{
        padding: 30px 0px;
    }  
    .mainListsContainer{
        width: 100%;
    }
    .mainListLiH5{
        font-size: 18px;
        line-height: 22px;
    }
    .mainSmallP{
        font-size: 16px;
        line-height: 20px;
    }
    .btnDiv{
        width: 75%;
        display: flex;
        flex-wrap: no-wrap;
        justify-content: space-between;
        padding-bottom: 30px;
    } 
       /*  */
    .mainPrice{
        width: 70%;
        font-size: 17px;
        line-height: 21px;
        padding: 0px 20px;
    }    
    .divPrice{
        width: 100%;
        padding: 25px 25px 25px 25px;
    }   
    .divPriceBorder{
        border-right: none;
    }
    .examType{
        width: calc((100% / 3) - 10px);
    }   
    .classType{
        width: calc((100% / 2) - 10px);
        border: 1px solid #C4C4C4;
        padding: 10px 5px;
    }     
    .form{
        padding: 10px 50px 10px 0px;
    }   
    .td2{
        font-size: 14px;
        line-height: 22px;
        padding: 15px 0;
    }
    .td3{
        font-size: 18px;
        line-height: 24px;
    }   
    .secondSec{
        margin-bottom: 50px;
    }
}

@media only screen and (min-width:993px) and (max-width:1060px){
    .examImage{
        background-position: right;
        background-size: unset;
    }  

    .examWidth{
        width: 100%;
    }
    .firstSectionDivH4{
        font-size: 28px;
        line-height: 35px;
    }
    .firstSectionDivP{
        font-size: 18px;
        line-height: 29px;
    }    
    .mainP{
        padding: 30px 0px;
        font-size: 20px;
        line-height: 25px;
    }
      
    .mainFSection{
        padding: 30px 0px;
    }  
    .mainListsContainer{
        width: 100%;
    }
    .mainListLiH5{
        font-size: 20px;
        line-height: 25px;
    }
    .mainSmallP{
        font-size: 18px;
        line-height: 23px;
    }
    .btnDiv{
        width: 55%;
    }  
     /*  */
    .mainPrice{
        width: 60%;
        font-size: 18px;
        line-height: 22px;
        padding: 0px 30px;
    }    
    .divPrice{
        width: 100%;
        padding: 25px 50px 25px 50px;
    }   
    .divPriceBorder{
        border-right: none;
    }
    .examType{
        width: calc((100% / 3) - 10px);
    }   
    .classType{
        width: calc((100% / 2) - 10px);
        border: 1px solid #C4C4C4;
        padding: 10px 5px;
    }     
    .form{
        padding: 10px 100px 10px 0px;
    }   
    .td2{
        font-size: 16px;
        line-height: 22px;
        padding: 15px 0;
    }
    .td3{
        font-size: 20px;
        line-height: 24px;
    }   
    .secondSec{
        margin-bottom: 50px;
    }
 
}
@media only screen and (min-width: 1061px) and (max-width: 1350px){
    .btnDiv{
        width: 60%;
    }
    
}