*{
    margin: 00;
    padding: 00;
}
.ol,ul,li{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}  
.firstSection{
    border-bottom: 1px solid #D5DFEC;
}
.abroadImage{
    background-image: url('./images/abroad.svg');
    background-repeat: no-repeat;
    background-position: right;
}

.mainContainer{
    padding: 30px 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.studyWidth{
    width: 1100px;
}
.mainP{
    padding: 0px 0px;
}
.mainListLiH5{
    width: 100%;
    padding: 30px 0px;
    border-bottom: 3px solid #D5DFEC;
    margin-bottom: 10px;
}
.countryBox{
    padding: 70px 0px;
}
.countryBox2{
    padding-bottom: 300px;
}
.countryBox3{
    padding-top: 10px;
}
.country{
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.0016em;
    padding: 15px 0px;
    color: rgba(30, 25, 61, 0.8);
}
.country:hover{
    background-color: rgba(196, 196, 196, 0.15);
    color: #97CDB7;
}
.imageContainer{
    width: 100%;  
    height: 250px; 
}
.mobileDisplay{
    display: none;
}
.countryPage{
    display: flex;
    flex-wrap: wrap;
}
.pageSide1{
    width: 30%;
    padding: 50px 25px;
    background: #3C3B6E;
    color: #fff;
}
.pageSide2{
    width: 70%;
    padding: 50px 50px;
    color: #1E193D;
}
.pageSideH4{
    font-size: 20px;
    margin-top: 20px;
}
.pageSideH5{
    font-size: 18px;
    line-height: 22px;
}
.pageP{
    font-size: 16px;
    line-height: 20px;
}
.listContainer{
    list-style: none;
}
.list{
    padding: 20px 0;
}
.list1{
    border-bottom: 1px solid #fff;
}
.disclaimer{
    background: #008751;
    border-radius: 21px;
    font-weight: bold;
    color: #fff;
    width: 30%;
    padding: 8px;
    text-align: center;
    margin-bottom: 15px;
}
.submitBtn{
    background-color: #3C3B6E;
    padding-left: 40px;
    padding-right: 40px;
    color: #fff;
    margin-top: 20px;
}
@media only screen and (max-width:600px){
    .webDisplay{
        display: none;
    }
    .abroadImage{
        background-image: url('./images/abroad.svg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 70%;
        justify-content: center;
    }
    .abroadTextContainer{
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    /* .collabBoxUSA{
        background-image: url('./images/usamobile.svg');
    } */
    .abroadTextContainer{
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .mainContainer{
        padding: 30px 25px;
    }  
    .studyWidth{
        width: 100%;
    }
    
    .country{
        font-size: 16px;
        line-height: 20px;
    } 
    .countryBox{
        padding: 30px 0px;
    }
    .countryBox2{
        padding-bottom: 100px;
    }
     
    .collab{
        width: calc((100% / 2) - 10px);
    }   
    .pageSide1{
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        /* height: 70px; */
    }
    .pageSide2{
        width: 100%;
        padding: 50px 25px;
    }
    .disclaimer{
        width: 70%;
        padding: 6px;
    }   
    .mobileDisplay{
        display: initial;
    } 
    .imageContainer{
        height: fit-content;
    }
}
@media only screen and (min-width:601px) and (max-width:992px){
    .studyWidth{
        width: 100%;
    }
}
@media only screen and (min-width:993px) and (max-width:1060px){
    .studyWidth{
        width: 100%;
    }
}