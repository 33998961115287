/* .faqSign{
    
} */
.faqRes{
    height: 70px;
    background: #D5DFEC;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #3C3B6E;
    padding: 0px 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.upperSection{
    display: none;
}
.sectionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 25px;
}

.mainContainer{
    width: 100%;
}
.divContainer{
    border: 1px solid rgba(30, 25, 61, 0.2);
    padding: 10px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: #201B42;
}
.firstP{
    padding: 5px 0;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.secondP{
    display: none;
}
.secondPDisplay{
    display: block;
    padding: 10px 0;
    border-top: 1px solid rgba(30, 25, 61, 0.2);
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    /* letter-spacing: 0.0016em; */
    letter-spacing: 0.005em;
}
.searchContainer{
    padding: 15px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchPoint{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.searchTopP{
    width: 100%;
    text-align: left;
}
.filterSec{
    width: 100%;
    margin-bottom: 20px;
}
.filterText{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 00.0016em;
    color: #313059;
    margin-bottom: 10px;
}
.filteredResult{
    /* width: 142px;
    height: 28px; */
    background-color: #FBFAFF;
    border-radius: 30px;
    border: 0.5px solid #c1c1c1;
    height: 28px;
    width: 142px;
    padding: 5px 5px;
    text-align: center;
    /* line-height: 28px; */
    margin-left: 20px;
}
.filterImage{
    width: 18px;
    height: 12px;
    background-image: url('./images/filter.svg');
    background-position: bottom;
    background-repeat: no-repeat;
}
.phoneFilter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.desktopFilter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px 20px;
    border-bottom: 1px solid #cdcdcd;
    padding: 10px 20px;
}
.desktopFilter2{
    justify-content: space-between;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px 20px;
    border-bottom: 1px solid #cdcdcd;
    padding: 10px 20px;
}
.desktopFilterLast{
    border-bottom: none;
}
.labelStyle{
    width: 85%;
}
.inputStyle{
    width: 100%;
    height: 35px;
    padding: 10px 5px;
}
.labelStyle2{
    display: none;
}

.desktopNav{
    display: none;
    justify-self: flex-end;
}
.phoneNav{
    /* display: block; */
    width: 250px;
    padding: 15px 0px;
    border: 1px solid #cdcdcd;
}    
.filterUpText{
    margin-bottom: 0;
}
.mainFilterText{
    padding: 10px 0px;
}
.divFilterText{
    padding: 20px 10px;
    border: 1px solid #D5DFEC;
    border-radius: 4px;
    margin-bottom: 15px;
}
.filterH5{
    margin: 3px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.0016em;
    color: #313059;
}
.filterTopicText{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.0016em;
    color: #313059;
    margin-bottom: 00;
}
.filterTopicText:hover{
    color: #97CDB7;
}
@media only screen and (min-width: 600px){
    .faqRes{
        padding: 0px 40px;
    }
    .sectionContainer{
        padding: 70px 40px;
    }

}
@media only screen and (min-width: 768px) {
    .labelStyle2{
        width: calc(100% - 300px);
        display: initial;
    }    
    .filterSide1{
        width: 320px;
        align-self: flex-start;
        justify-self: flex-end;
        display: flex;
        justify-content: flex-start;
        margin-top: 67px;
    }
    .searchContainer{
        padding: 15px 40px;
    }    
    .searchPoint{
        justify-content: space-between;
    }    
    .mainFilterText{
        width: 368px;
    }
    .phoneNav{
        display: none;
    }    
    
    .desktopNav{
        display: block;
        width: 250px;
        padding: 15px 0px;
        border: 1px solid #cdcdcd;
    }    
    .phoneFilter{
        display: none;
    }
    .filterText{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 00.0016em;
        color: #313059;
    } 
    .checkContainer{
        width: 100%;
    }

    .checkText{
        width: calc(100% - 32px);
        margin-bottom: 0;
    }  
}
@media only screen and (min-width:992px){
    .faqRes{
        padding: 0px 70px;
    }
    .sectionContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 70px 70px;
    }
    .searchContainer{
        padding: 15px 70px;
    }
    
    .mainFilterText{
        width: 550px;
    }
}
@media only screen and (min-width:1200px){
    .faqRes{
        display: none;
    }
    .upperSection{
        padding-left: 70px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }   
    .faqTopContainer{
        width: calc(100% - 70px);
        display: flex;
        justify-content: center;
        align-content: center;
    }     
    .faqImage{
        width: 40%;
        background-image: url('./images/faq.svg');
        height: 295px;
    }
    .faqText{
        width: 60%;
        background: #D5DFEC;
        height: 295px;
        padding: 50px 70px;
        display: flex;
        justify-content: flex-start;
        align-content: center;
    }
    .topP{
        font-size: 48px;
        line-height: 61px;
        letter-spacing: -0.02em;
        color: #3C3B6E;
        font-weight: 700;
    }
    .faqSideContainer{
        width: calc(100% - 1100px);
        background: #D5DFEC;
        height: 295px;
    }
    .mainContainer{
        width: 1100px;
    }
    .divContainer{
        width: 897px;
    }
    .searchPoint{
        width: 1100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }    
    .mainFilterText{
        width: 700px;
    }

}
/* <div className={searchstyles.divFilterText}>
<h5 className={searchstyles.filterH5}>Study in USA</h5>
<p className={searchstyles.filterTopicText}>
The United States of America (USA) hosts the most number of international students in the world. Quality education, unique curriculum, multicultural environment, and abundant opportunities...
</p>
</div>, */
