.firstSection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    flex-wrap: wrap;
}
.reviewImage{
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('./images/testimonial.svg');
}
.reviewTopic{
    width: 100%;
    background: #3C3B6E;
    height: 100px;
    padding: 10px 25px;
}
.reviewTopicDiv{
    color: #fff;
    font-size: 20px;
}
.reviewMain{
    padding: 15px 25px 50px 25px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}
.reviewMainDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    flex-wrap: wrap;
}
.reviewTest{
    border: 1px solid #D5DFEC;
    border-radius: 20px;
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.imageContainer{
    width: 150px;
}
.reviewP{
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0016em;
    color: #313059;
    text-align: justify;
    margin-top: 20px;
}
.widthDiv{
    width: 100%;
    background-color: #fff;
    height: 250px;
    padding: 10px 25px;

}
.widthDiv2{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.widthH5{
    width: 100%;
    border-bottom: 2px solid #CACDD2;
    padding: 10px 0;
    margin: 0;
    font-size: 24px;
    color: #3C3B6E;
}
.widthP1{
    width: 100%;
    padding: 10px;
    padding: 10px 15px;
    margin: 0;
    font-size: 18px;
    line-height: 29px;
    color: #3C3B6E;
}
.widthP1:hover{
    background: rgba(196, 196, 196, 0.15);
}
.widthBtn{
    width: 150px;
    height: 40px;
    border-radius: 4px;
    background-color: #3C3B6E;
    color: #fff;
    margin-top: 30px;
}
.formReview{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.widthRate{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}
.fullWidth{
    width: 100%;
    height: 35px;
}
.fullTextArea{
    width: 100%;
}
.paddingLabel{
    padding: 5px 0px 5px 0px;
}
.marginBtn{
    margin-bottom: 15px;
}
.alignBtn{
    align-items: center;
    justify-content: flex-start;
}
.subBtn{
    background: #3C3B6E;
    border-radius: 4px;
    Width: 67px;
    Height: 29.68px;
    color: #fff;
}
.fileLabel{
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    font-weight: 300;
    font-size: 7.5px;
    line-height: 9px;
    color: #313059;
    padding: 5px;
}
.fileInput{
    visibility: hidden;
    width: 130px;
}
@media only screen and (min-width:375px) {
    .fileInput{
        visibility: hidden;
        width: 160px;
    }    
}
@media only screen and (min-width:400px) {
    .fileInput{
        visibility: hidden;
        width: 175px;
    }    
}

@media only screen and (min-width:600px) and (min-width:768px) {
    .firstSection{
        padding: 10px 70px;
    }    
    .reviewTopic{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 104px;
        padding: 10px 40px;
    }
    .reviewMain{
        padding: 15px 70px 50px 70px;
    }   
    .widthDiv{
        padding: 10px 0px;
        height: 250px;
    }  
    .reviewP{
        width: 100%;
    }
    .formReview{
        width: 450px;
        padding: 10px 25px;
    }
    .fileInput{
        visibility: hidden;
        width: 150px;
    }
}
@media only screen and (min-width:992px){
    .reviewMainDiv{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }    
    .reviewTopic{
        padding: 10px 70px;
    }
    .widthDiv{
        padding: 10px 0px;
        width: 30%;
        align-self: flex-start;
    }
    .secondDiv{
        width: 60%;
    } 
    .widthDiv2{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }      
    .reviewP{
        width: 100%;
    } 
    .fileInput{
        visibility: hidden;
        width: 200px;
    }
}
@media only screen and (min-width:1200px){
    .reviewImage{
        width: 1100px;
    }    
    .reviewTopic{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 70px;
    }  
    .reviewTopicDiv{
        color: #fff;
        font-size: 20px;
        width: 1100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .reviewMainDiv{
        width: 1100px;
    }
    .reviewTest{
        justify-content: space-between;
    }    
    .reviewP{
        width: 70%;
    } 
    .fileInput{
        visibility: hidden;
        width: 230px;
    }
}