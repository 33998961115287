.rateNone{
    display: none;
}
.rateBlock{
    display: block;
    width: 100%;
}
.dopArrow{
  display: flex;
  justify-content: space-between;
}
.spanArrow{
  display: none;
}
.spanArrowDisplay{
  display: inline;
}

.rateContainer {
    /* padding: 16px; */
    margin: 16px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
  
  .result {
    text-align: center;
    margin-bottom: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .stars {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  
  .star {
    position: relative;
    cursor: pointer;
  }
  
  .stars_radio-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
  }