.whychooseus-wrapper {
  background: rgba(213, 223, 236, 0.4);
  padding: 4rem 14rem;
}
.whychooseus-wrapper p {
  font-size: 16px;
  line-height: 24px;
  color: #313059;
  letter-spacing: 00.0016em;
}
.whychooseus-wrapper .reason-number {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  background-color: #313059;
  color: #FFF;
}
.whychooseus-wrapper .reason-number,
.whychooseus-wrapper .reason-title {
  font-weight: bold;
}
.mr-3 {
  margin-right: 1rem;
}
.whychooseus-wrapper .reason-value {
  width: 80%;
} 

@media screen and (max-width: 768px) {
  .whychooseus-wrapper {
    padding: 4rem 2rem;
  }
  .whychooseus-wrapper .d-flex {
    flex-direction: column;
  }
}