.services-section {
  padding: 40px 0;
}
.container-fluid {
  width: 83%!important;
  margin: auto;
}
.service-wrapper {
  display: block;
  padding: 5px 20px;
  border-radius: 20px;
  border: 1px solid rgba(196, 195, 253, 0.3);
  text-align: center;
  padding-top: 15px;
  text-decoration: none;
  color: #313059;
  min-height: 340px;
}
.service-wrapper:hover {
  color: #97CDB7;
  background: #F4F4F4;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.service-wrapper img {
  height: 145px;
}
.service-wrapper h6 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}
.service-wrapper p {
  font-size: 16px;
  line-height: 22px;
}