*{
  box-sizing: border-box;
  margin: 00;
  padding: 00;
}
.App{
  box-sizing: border-box;
}
.ol,ul,li{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
