.hero-section {
  background-color: #3C3B6E;
  padding-top: 100px;
  padding-bottom: 70px;
}
.hero-section h1 {
  width: 94%;
  font-size: 64px;
  line-height: 87px;
  letter-spacing: -0.02em;
  color: #fff;
  font-weight: normal;
}
.hero-section h1 span {
  font-weight: bold;
}
.hero-section .input-group {
  border: 1.8px solid #313059;
  border-radius: 8px;
  margin-top: 2rem;
}
.hero-section .input-group-text {
  background: #fff;
  padding-left: 20px;
}
.hero-section .input-group-text,
.hero-section .form-control {
  border: 0;
}
.hero-section .form-control {
  font-size: 26.461px;
  line-height: 40px;
  letter-spacing: 0.0016em;
  color: rgba(49, 48, 89, 0.5);
}
.hero-section #button-addon2 {
  background: #008751;
  border-color: #008751;
}

@media screen and (max-width: 768px) {
  .hero-section .row {
    flex-direction: column-reverse;
  }
  .hero-section h1 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 15px;
  }
}