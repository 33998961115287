.formContainer{
    width: 70%;
    padding: 100px;
    padding-bottom: 200px;
    position: absolute;
    top: 400px;
    background-color: white;
    display: block;
    left: 20%;
    color: #313059;
}
.headerP{
    margin-bottom: 70px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #313059;
    /* color: rgba(0, 0, 0, 0.4);; */
}
.pModel{
    width: 100%;
    font-size: 14px;
    line-height: 22px;
}
.genWidth{
    width: 100%;
}
.headerUl{
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #3C3B6E;
}   
.headerli:hover{
    color: #3C3B6E;
    padding-bottom: 15px;
    border-bottom: 3px solid #3C3B6E;
}

.form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.PIFormGroup1{
    display: flex;
    gap: 20px 20px;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;
}
.labelGroup{
    width: 100%;
}
.PILabel{
    width: calc((100% / 2) - 20px);
    height: 30px;
    background-color: #fff;
    padding: 3px 0;
}
.PILabel2{
    width: 100%;
    height: 30px;
    background-color: #fff;
    padding: 3px 0;
}
.label2{
    display: flex;
}
.inputPlus{
    width: 30%;
    border: none;
    padding: 0 10px;
    border-left: 1px solid #C1C1C1;
    border-bottom: 1px solid #C1C1C1;
    border-top: 1px solid #C1C1C1;
}
.inputTel{
    width: 70%;
    border: none;
    border: 1px solid #C1C1C1;
}
.PIInput{
    height: inherit;
    width: 100%;
    border: none;
    padding: 0 10px;
    border: 1px solid #C1C1C1;
    border-radius: 4px;
}
.PILabel1{
    width: calc((100% / 3) - 20px);
    height: 30px;
    background-color: #fff;
}
.btn{
    border-radius: 4px;
    padding: 8px 90px;
    margin-top: 40px;
    background: #3C3B6E;
    color: white;
}
/*  */
.radBtnGroup{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
}
.radLabel{
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
}
.radLabel2{
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.0016em;
    margin-right: 5px;
}
.radBtn{
    border: 0px;
    height: 0.6em;
    margin-right: 4px;
}
.checkBtn{
    border: 0px;
    height: 0.8em;
    margin-right: 4px;
}
.inputBoxGroup{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px 10px;
    margin-top: 10px;
}
.inputBox{
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    border: 1px solid #C1C1C1;
}
.inputBox::placeholder,.inputBox2::placeholder,.inputExtension::placeholder{
    font-size: 11px;
}
.inputBoxGroup2{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
.labelHalf{
    width: calc((100% / 2) - 10px);
    height: 30px;
}
.inputExtension{
    margin-left: 10px;
    border: none;
    border-bottom: 1px solid #C1C1C1;
}
.labelHalf2{
    width: calc((100% / 2) - 10px);
}
.labelHalf3{
    width: calc(100% / 3);
}
.agree{
    width: 100%;
    margin-top: 20px;
}
.inputBoxGroup3{
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px 10px;
    margin-top: 10px;
}

.summary{
    width: calc(50% - 10px);
    height: 70px;
}
.summaryP{
    padding: 10px 40px 10px 5px;
    height: unset;
    /* line-height: 70px; */
}

.summaryInput{
    width: 100%;
    border: 1px solid #C1C1C1;
    padding: 5px;
    height: inherit;
}
.sumLastLabel{
    display: flex;
    justify-content: space-between;
}
.summaryInput2{
    width: 10%;
    border: 1px solid #C1C1C1;
    border-right: none;
    height: 70px;
    padding: 5px;
}
.summaryInput3{
    width: 90%;
    border: 1px solid #C1C1C1;
    height: 70px;
    padding: 5px;
}
@media only screen and (max-width:600px){
    .PI{
        display: none;
    }
    .EB{
        display: none;
    }
    .EI{
        display: none;
    }
    .Sum{
        display: none;
    }
    .formContainer{
        width: 90%;
        padding: 25px;
        left: 5%;
        right: 5%;
    }
    .labelGroup{
        width: 100%;
    }
    .PILabel{
        width: 100%;
    }
    .PILabel2{
        width: 100%;
    }
    .PILabel1{
        width: 100%;
    }
    .radBtnGroup{
        width: 100%;
    }  
    .inputBoxGroup{
        width: 100%;
    }  
    .inputBoxGroup2{
        width: 100%;
    } 
    .labelHalf,
    .labelHalf2,
    .labelHalf3{
        width: 100%;
    }
    .labelHalf:last-child{
        margin-top: 10px;
    }
    .inputBoxGroup3{
        justify-content: space-between;
        width: 100%;
        padding: 30px 0;
    }
    .summary{
        width: 60%;
        height: 40px;
    }
    .summaryP{
        padding: 5px 0px 5px 0px;
        width: 30%;
        height: unset;
        /* line-height: 30px; */
    }
    .summaryInput{
        width: 100%;
    }
    .summaryInput2{
        width: 15%;
        height: 40px;
    }
    .summaryInput3{
        height: 40px;
    }    
}
@media only screen and (min-width:601px) and (max-width:992px){
    .formContainer{
        width: 80%;
        padding: 50px;
        padding-bottom: 200px;
        left: 10%;
    }
}
@media only screen and (min-width:993px) and (max-width:1060px){
    .formContainer{
        width: 70%;
        padding: 50px;
        left: 15%;
        padding-bottom: 200px;
    }
}