@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slick-slider {
    width: 100% !important;
}
/* .slick-initialized {  */
    /* overflow-x: hidden; 
    overflow-y: hidden; */
  /* } */
  .slick-slide{
    margin-left:  15px;
    margin-right:  15px;
    width: 1100px !important;
  }
  .slick-dots{
    bottom: -35px;
    overflow: initial;
    display: none !important;
  }
  .slick-list {
    margin-left: -15px;
    margin-right: -15px;
    width: 1100px !important;
  }
  
  .slick-track {
    display: flex;
    /* width: 100% !important; */
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  
  @media only screen and (max-width:600px){
      .slick-slide{
        margin-left:  0px;
        margin-right:  0px;
        width: 300px !important;
      }
      .slick-slider{
        width: 300px;
      }
      
      .slick-list {
        margin-left: -1px;
        margin-right: -1px;
        width: 300px !important;
      }
      .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
      }
    }
    @media only screen and (min-width:601px) and (max-width:992px){
      .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
      }
      .slick-slide{
        margin-left:  7px;
        margin-right:  7px;
        width: 600px !important;
      }
      
      .slick-list {
        margin-left: -7px;
        margin-right: -7px;
        width: 600px !important;
      }
      
    }
    @media only screen and (min-width:901px) and (max-width:1024px){
      .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
      }
      .slick-slide{
        margin-left:  7px;
        margin-right:  7px;
        width: 900px !important;
      }
      
      .slick-list {
        margin-left: -7px;
        margin-right: -7px;
        width: 900px !important;
      }
      
    }
  