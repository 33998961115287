.marginReset1{
    margin-bottom: 30px;
}
.marginReset2{
    margin-bottom: 10px;
}
.marginReset3{
    margin-bottom: 30px;
}

.resetContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.resetContainerPadding{
    padding: 10px 20px;
}
.resetContainer2{
    display: flex;
    flex-direction: column;
}
.imageBackground{
    width: 150px;
    height: 150px;
    background-image: url("./images/passwordlock.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.imageBackground2{
    width: 150px;
    height: 150px;
    background-image: url("./images/linksent.svg");
    background-position: center;
    background-repeat: no-repeat;
}
.h4Reset{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #1E193D;
}
.pReset{
    font-family: Colasta Light;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #1E193D;
}
.pWidth{
    width: 400px;
    text-align: center;
}
.pResetBold{
    font-weight: 600;
}
.resetEmailForm{
    display: flex;
    flex-direction: column;
    width: 380px;
    height: 180px;
}
.resetInput{
    height: 50px;
    background: rgba(30, 25, 61, 0.01);
    border: 0.5px solid rgba(30, 25, 61, 0.3);
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
}
.resetInput::placeholder{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: rgba(46, 45, 84, 0.5);
}
.resetButton{
    width: 300px;
    height: 50px;
    background: #3C3B6E;
    border: 1px solid #FBFAFF;
    border-radius: 8px;
    align-self: center;
    color: #FBFAFF;
}
@media only screen and (max-width:600px){
    .resetContainerPadding{
        padding: 10px 20px;
    }
    .marginReset1{
        margin-bottom: 10px;
    }  
    .marginReset3{
        margin-bottom: 10px;
    }
      
    .resetContainer{
        width: 100%;
    }
    .imageBackground{
        width: 100px;
        height: 100px;
    }
    .h4Reset{
        font-size: 20px;
    }    
    .pWidth{
        width: 275px;
        text-align: center;
    } 
    .resetEmailForm{
        max-width: 275px;
        height: 150px;
        margin-top: 20px;
    }    
    .resetButton{
        width: 100%;
    }
       
}
@media only screen and (max-width:325px){
    .resetContainerPadding{
        padding: 10px 20px;
    }
    .marginReset1{
        margin-bottom: 10px;
    }  
    .marginReset3{
        margin-bottom: 10px;
    }
      
    .resetContainer{
        width: 100%;
    }
    .imageBackground{
        width: 100px;
        height: 100px;
    }
    .h4Reset{
        font-size: 20px;
    }    
    .pWidth{
        width: 250px;
        text-align: center;
    } 
    .resetEmailForm{
        max-width: 250px;
        height: 150px;
        margin-top: 20px;
    }    
    .resetButton{
        width: 100%;
    }

}
@media only screen and (max-width:850px) and (orientation:landscape){
    .resetContainer{
        height: 600px;
        width: 100%;
        position: absolute;
        left: 0%;
        top: 0%;
        transform: translate(0%,0%);    
    }
}