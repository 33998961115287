.HomeAbout{
    width: 100%;
}
/* .firstSection{
    padding: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3C3B6E;
} 
.homeImage{
    background-image: url('./images/Homepage.svg');
    background-repeat: no-repeat;
    background-position: right;
}*/
.aboutImage{
    background-color: #3C3B6E;
    background-image: url('./images/about.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 50%;
}
.galleryImage{
    /* background-color: #3C3B6E; */
    background-image: url('./images/Photography_illustration.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: 38%;
}

.adultImage{
    background-image: url('./images/adult.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 161px;
}
.langImage{
    background-image: url('./images/newlanguage.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 161px;
}
.tourImage{
    background-image: url('./images/tourism.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 161px;
}
/* .examImage{
    background-image: url('./images/exam.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 161px;
}
.abroadImage{
    background-image: url('./images/abroad.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 150px;
    width: 161px;
}
.tutorImage{
    background-image: url('./images/tutorsignup.svg');
    background-repeat: no-repeat;
    background-position: center;
} 
.firstSectionDiv{
    display: flex;
    flex-wrap: wrap;
    width: 1100px;
    padding-top: 40px;
    height: 400px;
}*/
.firstASecDiv{
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    text-align: justify;
    color: #FFFFFF;
}
/* .firstSectionDivP{
    font-size: 64px;
    line-height: 87px;
    letter-spacing: -0.02em;
    color: #FFF;
} */
.firstASecH1{
 font-size: 70px;
 line-height: 100px;
}
.firstASecDivP{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0016em;
}
/* .firstSectionDivButton{
    margin-top: 0px;
    padding: 3px 10px;
    background: #FDFDFD;
    border-radius: 4px;
    width: 200px;
    height: 45px;
    border: none;
} */
.allHeaders{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #313059;
}
.mainHome{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
} 
.genWidth{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 70px;
}
.genWidthChoose{
    background-color: rgba(213, 223, 236, 0.4);
}
/* .mostMainSections{
    padding: 100px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 1100px;
    padding: 0px 0px;
} */
.h4{
    width: 70%;
    font-size: 28px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #313059;
}
.h4Rule{
    border-bottom: none;
}
.h4Rule2{
    width: 80%;
    border-top: none;
}
.hr{
    width: 90%;
    margin-top: 30px;
}
/* .services{
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    width: 1100px;
}
.servicesPadding{
    padding: 0px 0px 30px 0px;
    max-width: 1000px;
    gap: 10px;
}
.servicesList{
    width: calc((100% / 3) - 10px);
    text-align: center;
}
.servicesListUnset{
    padding: 5px 0;
}
.servicesLink{
    color:"#313059";
    padding: 5px 20px;
    border-radius: 20px;
    border: 1px solid rgba(196, 195, 253, 0.3);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 15px;
    height: 280px;
}
.servicesLink:hover{
    color: #97CDB7;
    background-color: #F4F4F4;;
}
.pTopic{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #313059;
    padding: 5px 5px;
    margin-bottom: 0;
}
.pTopicUnset{
    color: inherit;
    width: 100%;
} */
.Section2Display{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.SectionChoose{
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.chooseUl{
    list-style: none;
    align-self: center;
    width: 918px;
    max-height: 500px;
    height: 350px;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr 0.1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 70px;
    grid-auto-flow: row;
    grid-template-areas:
        "scholarship straightHr consultation"
        "studyPacks straightHr departure";
}
.chooseUlLi{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.chooseUlLiP{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    background-color: #313059;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 00.0016em;
    color: #FFFFFF;
}
.chooseUlLiP2{
    width: calc(100% - 60px);
    font-size: 16px;
    letter-spacing: 0.0016em;
    color: #313059;
    font-weight: bold;
}
.pSpan{
    display: block;
    font-size: 16px;
    letter-spacing: 0.0016em;
    line-height: 22px;
    font-weight: normal;
    margin-top: 10px;
}
.scholarship { grid-area: scholarship; }
      
.consultation { grid-area: consultation; }

.studyPacks { grid-area: studyPacks; }

.departure { grid-area: departure; }

.straightHr { 
    grid-area: straightHr;
    position: relative; 
    display: flex;
}
.straightHrSpan{
    position: absolute;
    top: 30%;
    display: block;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    height: 100px;
}
.hRule{
    width: 80%;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
/* .authTutorSignUpSides{
    width: 1100px;
    display: flex;
    padding: 60px 70px;
    justify-content: space-between;
    align-items: center;
} */
.car{
    width: 100%;
}
.fig2{
    height: 400px;
    width: 40%;
}
/* .signUpDiv{
    width: 50%;
.authTFirstSideText{
    width: 100%;
    font-weight: 600;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: 0.0016em;
    color: #3C3B6E;
} 
.authTFirstSideTextP{
    width: 100%;
    letter-spacing: 0.0016em;
    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
    color: #313059;
}
.tutorApply{
    border-radius: 4px;
    background-color: #3C3B6E;
    padding: 10px 50px;
    color: #FFFFFF;
    margin-top: 70px;
    border: none;
}*/
.sectionTestimony{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 1100px;
    padding-bottom: 50px;
}
.testimonyH4{
    padding: 50px 0 20px 0;
}
.allListContainer{
    display: flex;
    width: 1200px;
    padding: 15px 70px;

    overflow: hidden;
}
.testimonyUl{
    width: 100%;
}
.testimonyUlLi{
    width: 500px !important;
    padding: 60px 25px;
    border-radius: 4px;
    border: 1px solid #D5DFEC;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}
.testimonyImageContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin-top: -300px; */
    z-index: 1000;
}
.testimonyImage{
    border-radius: 50%;
    width: 160px;
    height: 160px;
}
.testimonyCapP{
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #313059;
    text-align: center;
    width: 100%;
}
.testimonyCapSpan{
    display: block;
}
.SubscribeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sectionSubscribe{
    display: flex;
    min-width: 60%;
    padding: 10px 70px;
    justify-content: space-evenly;
    align-items: center;
}
.SubText{
    display: flex;
    align-items: center;
    justify-content: center;
}
.emailIcon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #313059;
}
.SubscribeP{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0016em;
    color: #CF1400;
    padding: 0 5px;
}
.SubscribeSpan{
    color: #313059;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0016em;
    padding: 0 5px;
}
.SubInput{
    max-width: 300px;
    padding: 10px 5px;
    border: 1px solid #313059;
    border-radius: 4px 0px 0px 4px;
}
.SubInput::placeholder{
    color: #313059;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0016em;
}
.SubBtn{
    max-width: 100px;
    padding: 10px 15px;
    background-color: #3C3B6E;
    color: white;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #313059;
}
.AboutStatements{
    display: flex;
    width: 1100px;
    overflow: hidden;
    padding: 200px 70px;
}
.AStatementLi{
    width: 1100px;
    text-align: center;
}
.ATeamUlContainer{
    padding: 50px 70px;
    display: flex;
    justify-content: center;
}
.ATeamUl{
    display: flex;
    width: 1100px;
    justify-content: center;
    align-items: center;
    gap: 10px 10px;
    list-style: none;
    flex-wrap: wrap;
}
.TeamH1{
    width: 100%;
    text-align: center;
    padding: 50px;
}
.ATeamUlLi{
    width: 337px;
}
.teamPhoto{
    height: 400px;
    width: 337px;
    background-repeat: no-repeat;
    background-size: cover;
}
.ceo{
    background-image: url('./images/Tosin/ceo.svg');
}
.alex{
    background-image: url('./images/Tosin/alex.svg');
}
.beamon{
    background-image: url('./images/Tosin/beamon.svg');
}
.jide{
    background-image: url('./images/Tosin/jide.svg');
}
.amazu{
    background-image: url('./images/Tosin/Amazu.svg');
}
.lawrence{
    background-image: url('./images/Tosin/Lawrence.svg');
}
.nonye{
    background-image: url(./images/Tosin/NOnye.svg);
}
.oluwaseyi{
    background-image: url('./images/Tosin/Oluwaseyi.svg');
}
.TeamFigure{
    border-radius: 4px;
}
.TeamFigureCap{
    background-color: #FFFFFF;
    border: 1px solid #C1C1C1;
    padding: 10px;
    text-align: center;
    width: 100%;
}
.TeamP{
    font-weight: bold;
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 0.0016em;
}
.TeamP2{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0016em;
}
.DoUlContainer{
    padding: 15px 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.DoUl{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1100px;
    gap: 40px 0;
    list-style: none;
    flex-wrap: wrap;
}
.DoUlLi{
    width: 100%;
    color: #313059;
}
.DoUlLi2{
    width: 100%;
    color: #313059;
}
.DoUlLi2:hover{
    background-color: rgba(196, 196, 196, 0.15);
    color: #97CDB7;
}

.DoP{
    padding: 10px 20px;
    font-weight: 900;
    font-size: 36px;
    line-height: 47px;
    border-bottom: 2px solid #3C3B6E;
}
.DoP2{
    padding: 10px 20px;
    font-size: 18px;
    line-height: 29px;
}
.dopArrow{
    display: flex;
    justify-content: space-between;
}
.spanArrow{
    display: none;
}
.spanArrowDisplay{
    display: inline;
}
.GalleryUl{
    gap: 0 30px;
    flex-wrap: wrap;
    width: 1200px;
    padding: 15px 70px;
    overflow: hidden;
}
.gal{
    width: 1000px !important;
    background-color: rosybrown;
    padding: 10px 30px;
}
.GalleryUlLi{
    width: 313px;
    height: 417px;
}
.videoLi{
    width: 100%;
    /* height: 417px; */
    margin-top: 40px;
}
video {
    width: 100%;
    height: auto;
}
.ClientUl{
    padding: 15px 0px;
    width: 1100px;
    list-style: none;
    gap: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.ClientUlLi{
    width: calc((100% / 3) - 10px);
    height: 150px;
    background-color: #C4C4C4;
}
.EnquiryContainer{
    padding: 15px 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.Enquiry{
    padding: 200px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px 0;
    width: 1100px;
}
.EnquiryP{
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.0016em;
    color: #313059;
}
.EnquiryP2{
    width: 60%;
    text-align: center;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.0016em;
    color: #313059;
}
.EnquiryDiv{
    width: 100%;
    text-align: center;
}
.EnquiryBtn{
    padding: 20px 55px;
    border-radius: 4px;
    border: none;
    background-color: #3C3B6E;
    color: #FFFFFF;
    font-size: 20px;
    letter-spacing: 0.0016em;
}
.GalleryCompH1{
    font-weight: bold;
    font-size: 120px;
    line-height: 183px;
    letter-spacing: 0.0016em;
    color: #FFFFFF;
}
.topButtonContainer{
    width: 100%;
    padding: 15px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GalleryCompUl{
    gap: 30px 0px;
    padding: 100px 70px;
    flex-wrap: wrap;
    width: 100%;
}
.GalleryCompUlLi{
    width: 100%;
    height: 450px;
    background-color: rosybrown;
}
.galContainer{
    padding: 15px 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shortGallery{
    width: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 10px;
    list-style: none;
}

@media only screen and (max-width:600px) {
    .homeImage{
        background-position: top center;
        background-size: 70%;
    }  
    .aboutImage{
        background-position: top;
        background-size: 70%;
    }
    .galleryImage{
        background-position: top;
        background-size: 58%;
    }
    
    .allListContainer{
        display: flex;
        width: 100% !important;
        padding: 15px 70px;
    
        overflow: hidden;
    }    
    .firstSection{
        height: fit-content;
        justify-content: flex-start;
        padding: 15px 25px;
        align-items: flex-end;
    }    
    .galleryContainer{
        height: 250px;
    }
    .firstSectionDiv{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-end;
    }
    .firstSectionDivAbout{
        justify-content: center;
    }
    .gallerySectionDiv{
        height: 150px;
        justify-content: center;
    }
    .firstASecDiv{
        width: 90%;
    }    
    .firstSectionDivButton{
        width: 70%;
    }    
    .firstSectionDivP{
        font-size: 24px;
        line-height: 32px;
        width: 70%;
    }
    .firstASecH1{
        font-size: 29px;
        line-height: 50px;
    }  
    .firstASecDivP{
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.0016em;
    }         
    .mostMainSections{
        padding: 15px 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }    
    .h4{
        font-size: 24px;
    }    
    .servicesPadding{
        padding-left: 0px;
        padding-right: 0px;
        max-width: 100%;
        gap: 25px;
    }
    .servicesList{
        width: 100%;
        padding: 8px 0px;
        text-align: center;
    } 
    .allListContainer{
        width: 100%;
        padding: 15px 0px;
    
        overflow: hidden;
    }
    .SectionChoose{
        padding: 25px 25px;  
        padding-bottom: 40px;      
    }   
    .chooseUl{
        width: 100%;
        max-height: unset;
        height: unset;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 20px 0px;
        grid-template-areas:
          "scholarship"
          "consultation"
          "studyPacks"
          "departure";
    }  
      
    .chooseUlLi{
        flex-wrap: wrap;
    }
    .chooseUlLiP2{
        width: 100%;
        font-size: 16px;
        letter-spacing: 0.16px;
    }
    .chooseUlLiP2:last-child{
        margin-top: 20px;
    }
    .pSpan{
        letter-spacing: 0.16px;
        margin-top: 1px;
    }
    
      .scholarship { grid-area: scholarship; }
      
      .consultation { grid-area: consultation; }
      
      .studyPacks { grid-area: studyPacks; }
      
      .departure { grid-area: departure; }
      .straightHr { 
        display: none;
      }
      /* .authTutorSignUpSides{
        flex-wrap: wrap;
        padding: 15px 25px;
    } */
    .fig2{
        height: 250px;
        width: 100%;
    }
    /* .signUpDiv{
        width: 100%;
        text-align: center;
    } */
    .authTFirstSideText{
        font-size: 24px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .tutorApply{
        margin-top: 15px;
    }
    .sectionTestimony{
        width: 100%;
        padding: 15px 25px;
    }    
    .testimonyUl{
        width: 100%;
        flex-wrap: wrap;
    }
    .testimonyUlLi{
        max-width: 320px !important;
        height: 250px;
        height: fit-content;
        padding: 15px 25px;
        margin-right: 20px;
        text-align: justify;
    }
    .testimonyCapP{
        font-size: 12px;
        line-height: 15px;
    } 
.sectionSubscribe{
    width: 100%;
    padding: 15px 25px;
    padding-top: 5px;
    flex-wrap: wrap;
}
.SubText{
    display: flex;
    align-items: center;
    justify-content: center;
}
.emailIcon{
    display: none;
}
.SubscribeP{
    width: 100%;
    font-size: 14px;
}
.SubscribeSpan{
    width: 100%;
    font-size: 14px;
}
.SubInput{
    max-width: 65%;
}
.SubBtn{
    max-width: 35%;
}
.AboutStatements{

    padding: 30px 25px;
    flex-wrap: wrap;
}
.AStatementLi{
    width: 100%;
}
.ATeamUlContainer{
    padding: 50px 0px;
}
.ATeamUl{
    padding: 15px 25px;
}
.ATeamUlLi{
    width: 100%;
}
.DoUlContainer{
    padding: 50px 0px;
}
.DoUl{
    padding: 15px 25px;
    gap: 10px 0;
}
.DoUlLi{
    width: 100%;
}
.DoP{
    padding: 10px 0px;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    border-bottom: 2px solid #3C3B6E;
}
.DoP2{
    padding: 10px 0px;
    font-size: 16px;
    line-height: 27px;
}
.galContainer{
    width: 100%;
    padding: 15px 25px;
}
.GalleryUl{
    width: 100%;
    padding: 15px 25px;
    height: 1600px;
}

.GalleryUlLi{
    width: 100%;
    height: 430px;
}

.ClientUl{
    padding: 15px 25px;
    gap: 10px 10px;
    width: 100%;
}
.ClientUlLi{
    width: calc((100% / 2) - 10px);
    height: 80px;
}
.ClientUlLi:last-child{
    display: none;
}
.EnquiryContainer{
    padding: 15px 0px;
}
.Enquiry{
    padding: 30px 25px;
    gap: 10px 0;
}
.EnquiryP{
    font-size: 20px;
    line-height: 30px;
}
.EnquiryP2{
    width: 100%;
    font-size: 15px;
    line-height: 24px;
}
.EnquiryDiv{
    display: none;
}
.GalleryCompH1{
    font-weight: bold;
    font-size: 36px;
    line-height: 70px;
    letter-spacing: 0.0016em;
    color: #FFFFFF;
    text-align: center;
}
}

@media only screen and (min-width:601px) and (max-width:992px){
    .homeImage{
        background-size: 60%;
    }  
    .aboutImage{
        background-size: 50%;
    }  
    .firstASecDiv{
        width: 50%;
    }  
    .allListContainer{
        width: 100%;
    }  
    .servicesPadding{
        gap: 50px 10px;
    }    
    .servicesList{
        width: calc((100% / 2) - 10px);
    }    
    .chooseUl{
        width: 85%;
        padding-top: 50px;
        max-height: 600px;
        min-height: 500px;
    }  
    .AboutStatements{
        width: 100%;
    }
    /* .authTutorSignUpSides{
        justify-content: space-between;
    }       */
    .fig2{
        width: 40%;
    }
    /* .signUpDiv{
        width: 50%;
    }  */
    .galleryContainer{
        height: 400px;
        padding: 100px 70px;
    }
    .gallerySectionDiv{
        height: 180px;
        justify-content: space-between;
    }
    .mostMainSections{
        padding: 100px 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 100px 70px;
    }
    .sectionTestimony{
        width: 100%;
    }
    .testimonyUlLi{
        width: 500px !important;
    }
    .sectionSubscribe{
        min-width: 100%;
        padding: 10px 40px;
    }    
    .ATeamUl{
        gap: 20px 20px;
    }    
    .ATeamUlLi{
        width: 260px;
    }
    .teamPhoto{
        height: 400px;
        width: 260px;
    }
    
    .GalleryCompH1{
        font-size: 60px;
        line-height: 140px;
    }      
    .GalleryCompUl{
        padding: 100px 100px;
    } 
    .GalleryUl{
        gap: 0 30px;
        flex-wrap: wrap;
        width: 100%;
        padding: 15px 70px;
        /* height: 1000px; */
    }
    
    .gal{
        /* width: 100% !important; */
        background-color: rosybrown;
        padding: 10px 30px;
    }
       
}
@media only screen and (min-width:901px) and (max-width:1025px){
    .firstASecDiv{
        width: 50%;
    }  
    .mostMainSections{
        width: 100%;
    }  
    .sectionTestimony{
        width: 100%;
    }
    .allListContainer{
        width: 100%;
    }  

    .ATeamUlLi{
        width: 260px;
    }
    .teamPhoto{
        height: 400px;
        width: 260px;
    }
    .GalleryCompH1{
        font-size: 80px;
        line-height: 190px;
    }        
    .GalleryCompUl{
        padding: 100px 100px;
    }    
    .GalleryUl{
        gap: 0 30px;
        flex-wrap: wrap;
        width: 100%;
        padding: 15px 70px;
    }

}
