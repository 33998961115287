.Guaranteed{
    height: 124px;
    width: 100%;
    font-family: Almarose Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 124px;
    text-align: left;
    letter-spacing: 0.0016em;
    color: #008751;
    margin-bottom: -35px;
}
.progressContainer{
    background: #3C3B6E;
    display: flex;
    justify-content: center;
}
.progress{
    background: #3C3B6E;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 1100px;
    padding: 15px 70px;
}
.progressFirstP{
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.0016em;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.progressFirstH1{
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 57px;
    text-align: center;
    letter-spacing: 0.0016em;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.realProgressDiv{
    background: #3C3B6E;
    height: 97px;
    width: 100%;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.progressLastH1{
    font-size: 97px;
    line-height: 97px;
    color: #FDFDFD;
    opacity: 0.8;
    text-align: right;
    margin-bottom: -24px;
}
.progressLastP{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.0016em;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.progressDiv{
    width: 70%;
    height: 0px;
    border-bottom: 2px dashed #FFFFFF;
    margin-bottom: 10px;
}
.dropDown{
    margin-left: 15px;
    display: none;
}
.listStyle{
    list-style: none;
}
.fix{
    position: fixed;
    bottom: 0;
}
.footer{
    padding: 15px 70px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    color: #fff;
    background: #3C3B6E;
    justify-content: center;
}
.footerNav{
    padding: 40px 00;
    width: 1100px;
    /* height: 350px; */
    display: flex;
    justify-content: space-between;
}
.footerP{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #FBFAFF;
    margin-bottom: 15px;
}
.footerListStyle{
    list-style: none;
}
.footerList{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #FBFAFF;
    text-decoration: none;
}
.footerA{
    display: flex;
    gap: 10px;
    align-items: center;
}
.footerListSpace{
    margin-bottom: 15px;
}
.footerCommunity{
    display: flex;
    width: fit-content;
    justify-content: space-between;
}
.smIcons{
    width: 32px;
    height: 32px;
}
.footerLastP{
    padding: 15px 70px;
    height: 61px;
    width: 100%;
    background: #140F33;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.0016em;
    margin-bottom: 0;
}
.up{
    display: none;
}
.up2{
    display: none;
}
@media screen and (max-width:600px){
    .footerNavMainDiv{
        width: 100%;
    }
    .listPhone{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .up{
        display: none;
    }
    .up2{
        display: inline;
    }
    .Guaranteed{
        height: 50px;
        font-size: 24px;
        line-height: 50px;
        margin-bottom: -18px;
    }
    /*  */
    .progress{
        height: 200px;
        padding: 15px 25px;
    }
    .progressFirstP{
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        width: 70%;
    }
    .progressFirstH1{
        font-size: 24px;
        line-height: 30px;
    }
    .realProgressDiv{
        height: 24px;
    }
    .progressLastH1{
        font-size: 24px;
        line-height: 24px;    
        margin-bottom: -5px;
    }
    .progressLastP{
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        width: 70%;
    }
    .progressDiv{
        width: 85%;
    }
    
    /*  */
    .flex{
        display: flex;
    }    
    .dropDown{
        margin-left: 15px;
        display: unset;
    }    
    .footer{
        padding: 15px 25px;
        /* justify-content: center;
        align-items: center; */
    }
    .footerNav{
        padding: 10px 00;
        /* height: 220px; */
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;
        width: 100%;
    }    
    .footerP{
        min-width: 30vw;
        text-align: left;
        padding: 0px 10px;
        margin-bottom: 0px;
    }       
    .footerListStyle{
        list-style: none;
        display: none;
    }  
    .footerClick{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px 20px;
    }  
    .footerListPhone{
        width: calc((100% / 2) - 20px);
    }
    /* .footerLastP{
        padding: 15px 25px;
    } */
    .socialNames{
        display: none;
    }
    .footerLastP{
        padding: 15px 10px;
        height: 50px;
        width: 100%;
        font-size: 10px;
    }
    .flexCommunityU{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 30px;
    }
    .footerCommunity{
        width: unset;
    }    
}
@media only screen and (min-width:601px) and (max-width:992px){
    .footerNavMainDiv{
        width: 166px;
    }
    .footerNav{
        padding: 10px 00;
        /* height: 220px; */
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 15px 80px;
        width: 100%;
    }   
    .socialNames{
        display: none;
    }
    .flexCommunityU{
        display: flex;
        width: 166px;
        justify-content: space-between;
        margin-top: 30px;
    }


}