.hamburger{
    display: none;
}
.centerContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 70px;
}
.listStyle1{
    list-style: none;
    display: inline;

}
.listStyle2{
    display: inline;
}
.container{
    padding: 50px 0px;
    justify-content: space-between;
    width: 1100px;
    gap: 30px 0;
    align-items: center;
}
.flexBox1{
    display: flex;
    flex-direction: row;
}
.flexBoxT{
    display: flex;
    flex-direction: row;
}
.flexBoxH{
    display: flex;
    flex-direction: row;
}
.flexBoxNav{
    display: flex;
    flex-direction: row;
}
.flexBox2{
    display: flex;
    flex-direction: column;
}
.wrap1{
    flex-wrap: wrap;
}
.wrap2{
    flex-wrap: nowrap;
}
.JContent1{
    justify-content: center;
}
.JContent2{
    justify-content: space-between;
}
.JContent3{
    justify-content: flex-end;
}
.JContent4{
    justify-content: flex-start;
}
.JContent5{
    justify-content: space-between;
}
.JContent6{
    justify-content: space-around;
}
.JContenNav{
    justify-content: center;
}
.AItemNav{
    align-items: center;
}
.AItem1{
    align-items: center;
}
.AItem2{
    align-items: flex-end;
}
.AItem3{
    align-items: flex-start;
}
.fngc{
   width: 120px;
   height: 210px; 
   background-image: url('./images/fnglogo.svg');
   background-position: center;
   background-repeat: no-repeat;
}
.authenticationBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.fig1{
    height: calc(100vh - 30vh);
    width: 530px;
    background-image: url('./images/signin.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.fig2{
    height: 350px;
    background-image: url('./images/tutorsignup.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.padding1{
    padding: 50px 20px;
}
.padding2{
    padding: 0px 60px;
}
.padding3{
    padding: 15px 70px;
    padding-top: 50px;
}
.paddingSignUp{
    padding: 15px 0;
}
.padding4{
    padding: 20px 20px;
}
.input{
    /* width: calc(100% - ); */
    width: 100%;
    height: 25px;
    padding: 17px 5px;
    border: 1px solid #3C3B6E;
    border-radius: 4px;
}

.input::placeholder{
    color: #313059;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
}
.pError{
    font-size: 10px;
    color: #F06C6E;
    display: none;
}
.pError2{
    font-size: 10px;
    padding: 10px 40px;
    text-align: center;
    color: #F06C6E;
    display: none;
}
/* .inputError{
} */

.inputAside{
    margin-right: 6px;
}
.label{
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
}
.labelBox{
    margin-top: 10px;
}
.labelSelect{
    margin-top: 10px;
}
.labelSelect2{
    margin-top: 5px;
}
.labelAside{
    margin-right: 10px;
}
.select{
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    border: 1px solid #C4C4C4;
}
.button{
    margin-top: 25px;
    width: 70%;
    height: unset;
    padding: 10px 5px;
    align-self: center;
    background: #3C3B6E;
    color: #fff;
    border-radius: 4px;
}
.para{
    line-height: 20px;
    font-size: 15px;
}
.already{
    width: 70%;
    line-height: 20px;
    text-align: center;
    align-self: center;
}
.already2{
    width: 60px;
}
.headernav{
    height: 100%;
}
.headerNav2{
    display: flex;
    justify-content: center;
    align-items: center;
}
.remove{
    display: none;
}
.headerU {
    padding: 0px 10px;
}
.headerULastChild {
    padding: 5px 25px;
    background-color:#3C3B6E;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
    border: none;
}

.header{
    width: 100%;
    height: 70px;
    border: 1px solid #C4C4C4;
    padding: 5px 70px;
    display: flex;
    justify-content: center;
    background-color: #fff;
}
.headerFig {
    height: 50px;
    width: 80px;
    background-image: url('./images/fnglogo.svg');
    background-position: top left;
    background-repeat: no-repeat;
}
.Nav{
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.overallAuthT{
    width: 100%;
}
.authTutorContainer{
    padding: 15px 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.authTutorSignUp{
    overflow-x: hidden;
    width: 1100px;
}

.authTutorSignUpSides{
    width: 50%;
}
/* .homeSpace {
    padding: ;
} */
.authTutorSignUpSides:last-child{
    width: 40%;
    padding: 5px 40px;
    
}
.authTFirstSideText{
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: 0.0016em;
    color: #3C3B6E;
}
.authTFirstSideText:last-child{
    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
    color: #313059;
}
.authT2ndSideDiv{
    width: 100%;
}
.authT2ndSideText{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #1E193D;
    margin-bottom: 20px;
}
.authTMethod{
    background: #337FFF;
    border-radius: 25px;
    padding: 10px 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    width: 260px;
}
.authTMethod2 {
    margin-top: 20px;
    background-color: #fff;
    color: #1E193D;
    border: 1px solid #1E193D;

}
.authTMethod3{
    margin-top: 20px;
    background: #3C3B6E;
    display: none;
}
.authTMethod4{
    border: none;
}
.authTMethodSub{
    width: 100px;
    padding: 10px 20px;
    font-weight: 600;
    line-height: 16px;
    font-size: 10px;
    background: #3C3B6E;
    border: 1px solid #3C3B6E;
}
.authTMethodLastDiv{
    display: none;
}
.authHr{
    width: 8vw;
    height: 0px;
    border: 1px solid #CACDD2;
}
.authHrText{
    margin-top: -10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.0016em;
    color: #000000;
}
.authSide{
    width: 500px;
}
.buttonSection{
    margin-bottom: 50px;
    height: 40px;
    width: 100%;
}
.buttonSectionGallery{
    width: 30%;
    margin-top: 50px;
}
.topButtons:nth-child(1){
    border: 1px solid #3C3B6E;
    width: calc(100% / 2);
    height: 35px;
    border-radius: 4px;
    border-collapse: collapse;
    border-bottom-right-radius: 0%;
    border-top-right-radius: 0%;
}
.topButtons:nth-child(2){
    border: 1px solid #3C3B6E;
    width: calc(100% / 2);
    height: 35px;
    border-radius: 4px;
    border-collapse: collapse;
    border-bottom-left-radius: 0%;
    border-top-left-radius: 0%;
}
.authSideForm{
    padding: 30px 30px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
}
.formPara{
    line-height: 29px;
    font-size: 18px;
    margin-bottom: 20px;
}
.formParaSpan{
    line-height: 24px;
    font-size: 14px;
}
.sideShadow{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
.sideShadowM{
    margin-top: 20px;
}
@media only screen and (max-width:600px){
    .buttonSectionGallery{
        width: 70%;
        margin-top: 50px;
    }    
    .flexBox1{
        flex-direction: column;
    }
    .fig1{
        display: none;
    }
    .padding1{
        padding: 40px 30px;
    }    
    .padding2{
        padding: 0px 0px;
    }   
    .centerContainer{
        padding: 0 0px;
    }
    .container{
        width: 100%;
    }
    
    .authSide{
        width: calc(100% - 60px)
    }
    .authSideForm{
        padding: 30px 20px;
        width: 100%;
    }
    .button{
        width: 100%;
    } 
    .listStyleH{
        display: none;
        margin-top: 250px;
        margin-right: -100px;
        /* display: unset; */
        background: #3C3B6E;
        color: #fff;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        height: 150px;
    }  
    .ham{
        background: #3C3B6E;
        color: #fff;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        height: 500px;
    }       
    .listStyle2{
        display: block;
    }  
    .hamburger{
        display: unset;
    }  
    .header{
        padding: 5px 25px;
    }  
    .Nav{
        width: 100%;
    }
    .headerHam{
        color: #313059;
    }
    .headerHam:hover{
        background-color: rgba(196, 196, 196, 0.15);
        color: #97CDB7;    
    }
    .headerNav{
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
    }
    .JContentNav{
        justify-content: space-around;
    }
    .AItemNav{
        align-items: center;
        align-items: flex-end;
    }  
    .padding3{
        padding: 15px 25px;
        padding-top: 30px;
    }
    .paddingSignUp{
        padding: 15px 25px;
        padding-top: 30px;
    }
    
    .flexBoxT{
        display: flex;
        flex-direction: column-reverse;
    }
    .authTutorContainer{
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }    
    .authTutorSignUp{
        width: 100%;
    }
    .authTutorSignUpSides{
        width: 100%;
    }  
    .fig2{
        display: none;
    }
    .authTFirstSideText{
        font-size: 30px;
    }
    .authTutorSignUpSides:last-child{
        margin-top: 20px;
        width: 100%;
    }
    .authT2ndSideDiv{
        padding: 40px 0px;
    }
    .authT2ndSideText{
        font-size: 14px;    
    }   
    .authTMethod{
        width: 240px;
    }     
    .authTMethod3{
        display: unset;
    }    
    .authHrDivContainer{
        display: none;
    }
    .authTForm{
        display: none;
    }
    .authTMethodSub{
        width: 100px;
    }
    .authTMethodLastDiv{
        align-self: center;
        width: 100%;
        display: flex;
        gap: 3px 10px;
        justify-content: space-around;
        align-items: center;
    }
    .authTMethodLast1{
        background: #337FFF;
        min-width: 100px;
    }
    .authTMethodLast2{
        min-width: 100Px;
        background-color: #fff;
        color: #1E193D;
    }
    
}
@media only screen and (min-width:601px) and (max-width:992px){
    .header{
        padding: 5px 30px;
    }
    .headerFig {
        width: 60px;
    }
    .headerNav{
        font-size: 14px;
    }
    .container{
        width: 100%;
    }
    
    .flexBox1{
        flex-direction: column;
    }
    .fig1{
        background-color: red;
        height: calc(100vh - 70vh);
        width: 100%;
        margin-bottom: 30px;
    }    
    .padding1{
        padding: 80px 80px;
    }    
    .authSide{
        width: calc(100% - 100px)
    }
    .Nav{
        width: 100%;
    }

    .authSideForm {
        width: calc(100% - 20px);
    }
    /*  */
    .overallAuthT{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .padding3{
        padding: 15px 25px;
        padding-top: 30px;
    }
    .paddingSignUp{
        padding: 15px 25px;
        padding-top: 30px;
    }

    .flexBoxT{
        display: flex;
        flex-direction: column-reverse;
    }
    .authTutorSignUp{
        width: 70%;
    }
    .authTutorSignUpSides{
        width: 100%;
    }  
    .authTutorSignUpSides:first-child{
        margin-top: 30px;
    }
    /* .fig2{
        display: none;
    } */
    .authTutorSignUpSides:last-child{
        margin-top: 20px;
        width: 100%;
        padding: 0px 20px;
    }
    .authT2ndSideDiv{
        padding: 40px 0px;
    }
    .authTMethod3{
        display: unset;
    }    
    .authHrDivContainer{
        display: none;
    }
    .authTForm{
        display: none;
    }
    .authTMethodLastDiv{
        align-self: center;
        max-width: 60%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .authTMethodLast1{
        background: #337FFF;
        min-width: 100px;
    }
    .authTMethodLast2{
        min-width: 100Px;
        background-color: #fff;
        color: #1E193D;
    }

}

@media only screen and (min-width:993px) and (max-width:1060px){
    .container{
        width: 100%;
    }

    .flexBox1{
        flex-direction: column;
    }
    .fig1{
        background-color: red;
        height: calc(100vh - 70vh);
        width: 100%;
        margin-bottom: 30px;
    }    
    .authSide{
        width: calc(100% - 35vw)
    }
    .authSideForm {
        width: calc(100% - 20px);
    }
    /*  */
    .overallAuthT{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .padding3{
        padding: 15px 25px;
        padding-top: 30px;
    }
    .paddingSignUp{
        padding: 15px 25px;
        padding-top: 30px;
    }
    .authTutorSignUp{
        overflow-x: hidden;
        width: 100%;
    }
    
    .authTutorSignUpSides:last-child{
        width: 50%;
    }
    .authTutorSignUpSides{
        width: 50%;
    }
    
    .authTutorSignUpSides:last-child{
        width: calc(50% - 5%);
        padding: 5px 20px;
        
    }    
    .authT2ndSideDiv{
        padding: 40px 0px;
    }
    .authHr{
        width: 14vw;
        /* height: 1px;
        border: 1px solid #CACDD2; */
    }
    
}
/* `${styles.normalLink} ${styles.linkButton}` */