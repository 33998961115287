.footerDown{
    bottom: 0px;
    bottom: 0px;
    /* position: fixed; */
    width: 100%;
}
.checkOutContainer{
    margin-top: 0px !important;
    height: calc(100vh - 511px);
}
.checkOut{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.checkWidth{
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #3C3B6E;
    margin-bottom: 50px;
}
.checkWidth2{
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #3C3B6E;
}
.btnDiv{
    width: 464px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 10px;
}
.btn{
    width: 222px;
    height: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
}
.btn1{
    background: #3C3B6E;
    color: #fff;
}
.btn2{
    color: #3C3B6E;
}
@media only screen and (max-width:600px){
    .footerDown{
        position: static;
        width: 100%;
    }
    .checkOutContainer{
        height: initial;
    }    
}

@media only screen and (min-width:601px) and (max-width:1050px) and (orientation:landscape){
    .footerDown{
        position: static;
        width: 100%;
    }
        .checkOutContainer{
        height: initial;
    }    

}
