.checkAppContainer{
    padding: 15px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.checkAppDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.errorSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.checkAppSection1{
    height: 300px;
    width: 100%;
}
.checkAppSection2{
    height: 500px;
    width: 90%;
    background: #FBFAFF;
    padding: 0px 10px;
}
.checkAppH4{
    width: 100%;
    border-bottom: 1px solid rgba(30, 25, 61, 0.2);
    padding-bottom: 20px;
    padding-top: 20px;
}
.checkAppTable{
    width: 100%;
    color: #1E193D;
}
.checkAppTable2{
    width: 100%;
    color: #1E193D;
}
.inputDiv{
    width: 100%;
    padding: 30px 0;
}
.checkOutInput{
    height: 35px;
    border: none;
    border-radius: 0;
}
.input{
    width: 70%;
    background: #fff;
}
.input::placeholder{
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.0016em;
    color: rgba(30, 25, 61, 0.4);
}
.checkbtn1{
    width: 30%;
    background: #D5DFEC;
    color: #313059;
    border-radius: 0px 4px 4px 0px;
}
.checkbtn2{
    width: 100%;
    background: #3C3B6E;
    color: #fff;
    border-radius: 4px;
    height: 35px;
    margin: 30px 0 0 0;
}
.checkOutP{
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.0016em;
    color: #3C3B6E;
    padding: 20px 0;
}
.errorSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 100px 0;
}
.errorFig{
    width: 100%;
    background-image: url('./images/error.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 194px;
}
.errorP{
    font-weight: 900;
    font-size: 10.8387px;
    line-height: 14px;
    color: #313059;
}
.errorBtnsDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.errorBtns{
    padding: 10px 10px;
    background: #3C3B6E;
    width: 50%;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #fff;
    border: none;
    width: 95px;
}
.errorBtn1{
    border-radius: 4px 0px 0px 4px;
}
.errorBtn2{
    border-radius: 0px 4px 4px 0px;
}
.contactFirstSection{
    height: 200px;
    justify-content: flex-start;
    padding: 15px 25px;
    align-items: flex-start;
}
.contactSection{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
}
.contactDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px 20px 20px;
    margin-top: -60px;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0px 3.94053px 7.88106px rgba(0, 0, 0, 0.08);
    margin-bottom: 50px;
}
.contactP{
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #313059;
}
.contactForm{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.formGroup1{
    width: 100%;
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
}
.inputGroup1{
    height: 35px;
    padding: 10px;
    width: 100%;
    border: 1px solid #C1C1C1;
    border-radius: 4px;
}
.formGroup2{
    gap: 20px 20px;
}
.contactInput::placeholder{
    font-size: 10px;
    line-height: 21px;
    color: #313059;
    opacity: 0.5;
}
.inputGroup3{
    height: 100px;
}
.contactBtn{
    width: 250px;
    height: 40px;
    margin-top: 20px;
    background: #3C3B6E;
    border-radius: 4px;
    color: #fff;
    margin-top: 30px;
}
@media only screen and (min-width:600px) and (min-width:768px) {
    .checkAppContainer{
        padding: 30px 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .checkAppSection1{
        width: 450px;
    }
    .checkAppSection2{
        width: 450px;
    }
    .errorFig{
        height: 450px;
    }
    .contactFirstSection{
        height: 350px;
        justify-content: flex-start;
        padding: 15px 25px;
        align-items: center;
    }  
    .contactDiv{
        width: 500px;
        margin-top: -70px;
        margin-bottom: 150px;
        padding: 10px 60px 80px 60px;
    }     
}
@media only screen and (min-width:992px){
    .checkAppContainer{
        padding: 15px 70px;
        flex-wrap: wrap;
        height: 580px;
    }
    .checkAppDiv{
        justify-content: space-between;
    }    
    .checkAppSection1{
        height: 700px;
        width: 400px;
    }
    .checkAppSection2{
        height: 700px;
        width: 350px;
    }
    .checkbtn2{
        width: 100%;
        background: #3C3B6E;
        color: #fff;
        border-radius: 4px;
        height: 40px;
        margin: 30px 0 0 0;
    }  
    .contactFirstSection{
        height: 350px;
        justify-content: flex-start;
        padding: 15px 70px;
        align-items: center;
    }   
    .contactDiv{
        width: 700px;
        margin-top: -70px;
        margin-bottom: 150px;
        padding: 10px 60px 80px 60px;
    }
    .inputGroup1{
        width: 250px;
    }
    .formGroup1{
        width: 40%;
        display: flex;
        gap: 20px 20px;
        flex-wrap: wrap;
    }
    .inputGroup1{
        height: 35px;
        padding: 10px;
        width: 100%;
        border: 1px solid #C1C1C1;
        border-radius: 4px;
    }
    
    .inputGroup2{
        width: 100%;
    }
    .formGroup2{
        width: 100%;
    }
 
}
@media only screen and (min-width:1200px){
    .checkAppDiv{
        width: 1100px;
    }
    .checkAppSection1{
        height: 700px;
        width: 450px;
    }
    .checkAppSection2{
        height: 700px;
        width: 420px;
        padding: 0px 20px;
    }
    .errorFig{
        height: 350px;
    }
    .errorSection{
        padding: 10px 0;
    }
    .contactFirstSection{
        justify-content: center;
    }   
    .inputGroup3{
        height: 100px;
    }
    
}